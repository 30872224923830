.payment-plan-container {
  width: 264px;
  border-radius: 8px;
  border: 1px;
  box-shadow: 0px 4px 24px 0px #00000012;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.payment-plan-header {
  background: #515151;
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.payment-plan-name {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #f9b24d;
  margin: 0;
  font-family: "Poppins Regular";
}

.payment-plan-value {
  font-family: Poppins;
  font-size: 26px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin: 0;
  font-family: "Poppins Regular";
}

.payment-plan-body {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
}

.payment-plan-observation {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  margin: 0;
  margin-bottom: 20px;
}

.payment-plan-body .product-item-container {
  display: flex;
  flex-direction: row;
  padding: 8px 0;
}

.payment-plan-body .product-item-container p {
  margin: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.payment-plan-body .product-item-container b {
  font-weight: 700;
}

.payment-plan-body .product-item-separator {
  height: 1px;
  background-color: #c9c9c9;
}

.payment-plan-body .subscribe-message {
  font-family: Inter;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #383838;
  margin: 20px 0;
}

.payment-plan-body .button-container {
  flex: 1;
  align-items: flex-end;
  display: flex;
  margin: 0;
}

.payment-plan-body .confirm-button {
  align-items: flex-end;
  width: 100%;
}
