#video-onboarding-data-validation-container {
  width: 607px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#data-validation-title {
  color: var(--darkGrey2);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 20px;
  margin-bottom: 24px;
  align-self: flex-start;
}

#data-validation-message {
  color: var(--black);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
}

#data-validation-message-bold {
  font-weight: 600;
}

#data-validation-data-container {
  border-radius: 8px;
  border: 0.8px solid #e1e1e1;
  background: #fff;
  box-shadow: 0px 1px 10px 0px rgba(220, 220, 220, 0.7);
  padding: 16px;
}

#data-validation-instructions {
  color: var(--black);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.5px;
  margin-top: 24px;
  margin-bottom: 32px;
  align-self: flex-start;
}

#data-validation-timer-message {
  color: var(--green);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.24px;
  align-self: flex-start;
  margin: 32px 0;
}
