.icon-search {
  transform: rotate(100deg);
  position: absolute;
  font-size: 474px;
  color: #ebebeb;
  padding: 44.47px 44.475px 44.47px 44.473px;
  left: -80.16px;
  top: 132.886px;
  display: flex;
}

#login-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

#login-inner-container {
  width: 452px;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.08);
  padding: 40px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.logo-login {
  width: 200px;
  height: auto;
  align-self: center;
}

.title-login {
  text-align: center;
  font-family: "Poppins";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.subtitle-login {
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

#login-inner-container .input-container {
  margin: 12px 0;
}

.forgotpassword-login {
  background-color: transparent;
  border: none;
  color: var(--green);
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}

.button-container {
  margin: 12px 0;
}

/* @import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@500&display=swap");



.container-login {
  display: flex;
  flex-direction: column;
  align-content: center;
}


.inputs-login {
  align-content: center;
}
.forgotpassword-login {
  background-color: transparent;
  border: none;
  color: var(--green);
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  position: relative;
  top: 5px;
  right: 78px;
}

.lupa-login {
  display: flex;
  width: 500px;
  height: auto;
  padding: 46.875px 46.88px 46.875px 46.878px;
  justify-content: center;
  align-items: center;
}

.search-background {
  color: red;
  width: 100px;
} */
