#steps-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  border-radius: 10px;
  border: 1px solid #ebf7f3;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(185, 178, 178, 0.2);
  margin-top: 40px;
  padding: 21px 86px;
  box-sizing: border-box;
}

#steps-indicators {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* padding: 0 51px; */
  box-sizing: border-box;
}

.step-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.step-item {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--inactive);
}

.step-item.active {
  background-color: var(--green);
}

.step-item .step-icon {
  font-size: 32px;
  color: var(--white);
}

.separator {
  width: 285px;
  height: 3px;
  background-color: var(--green);
  position: absolute;
  top: -24px;
  right: -48px;
}

/* .material-symbols-outlined {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
} */

.step-name {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 8px;
  color: var(--inactive);
}

.step-name.active {
  color: var(--green);
}

/* #steps-legend {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 51px;
  box-sizing: border-box;
} */
