#create-account-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#create-account-inner-container {
  background-color: var(--white);
  display: flex;
  padding: 88px 80px;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  width: 1128px;
  flex: 1;
  box-sizing: border-box;
}

#create-account-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 40px;
}

#create-account-buttons button {
  width: 210px;
  margin-left: 40px;
}

.register-title {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 40px 0;
  display: flex;
}

#password-form {
  display: flex;
  flex-direction: column;
}
