#address-form .input-container {
  width: 318px;
  float: left;
  margin-right: 40px;
  margin-bottom: 33px;
  margin-top: 0;
}

#address-cep {
  float: unset !important;
}
