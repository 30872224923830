@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

:root {
  --white: #ffffff;
  --darkGrey1: #212121;
  --darkGrey2: #383838;
  --darkGrey3: #565656;
  --lightGrey1: #f7f7f7;
  --lightGrey2: #c2c2c2;
  --lightGrey3: #515151;
  --black: #000000;
  --green: #006241;

  --background: #f6f6f6;
  --inactive: #cfcfcf;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  min-width: 100vw;
  height: 100vh;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}

.material-symbols-outlined.fill {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}

@font-face {
  font-family: GilroyExtraBold;
  src: url('./assets/fonts/Gilroy-ExtraBold.otf');
}

@media print {
  #mailing-contract {
    display: none;
  }
}

.react-pdf__Page {
  min-height: 0px !important;
  max-height: 1756px !important;

  .react-pdf__Page__textContent.textLayer, .react-pdf__Page__annotations.annotationLayer {
    display: none !important;
  }
}

.PhotoView-Portal {
  z-index: 10000 !important;
}

.PhotoView-Slider__BannerWrap, .PhotoView-Slider__ArrowLeft, .PhotoView-Slider__ArrowRight {
  z-index: 10001 !important;
}