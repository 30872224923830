#register-completed-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--white);
  padding: 88px 76px;
}

#register-completed-inner-container {
  width: 648px;
}

#register-completed-title-container {
  display: flex;
  align-items: center;
}

#register-completed-title-icon {
  font-size: 32px;
  color: var(--green);
}

#register-completed-title-text {
  color: var(--green);
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 16px;
}

#register-completed-message1 {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 40px 0;
  display: flex;
}

#register-completed-title2 {
  color: #212121;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

#register-completed-message2 {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 40px 0;
  display: flex;
}

#register-completed-buttons-container {
  display: flex;
  justify-content: flex-end;
}

#register-completed-buttons-container .button-container {
  width: 210px;
  margin-left: 40px;
}
