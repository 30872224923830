#plan-confirm-card-container {
  width: 648px;
  padding: 12px 16px;
  border-radius: 8px;
  border: solid 0.8px #e1e1e1;
  background-color: white;
  box-shadow: 0px 1px 10px 0px #dcdcdcb2;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

#plan-confirm-card-container p {
  margin: 0;
  color: #212121;
  letter-spacing: 0em;
  line-height: 24px;
}

#plan-confirm-card-container .title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: #006241;
}

#plan-confirm-card-container .card-value-label {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
}

#plan-confirm-card-container .card-value-value {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
}
