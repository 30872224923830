@font-face {
  font-family: 'Poppins Regular';
  src: url('./Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Bold';
  src: url('./Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter Regular';
  src: url('./Inter/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter Bold';
  src: url('./Inter/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}