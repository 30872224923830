#onboarding-link-sent-container {
  width: 648px;
  display: flex;
  flex-direction: column;
}

#onboarding-link-sent-container #success-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 68px;
  margin-bottom: 44px;
}

#onboarding-link-sent-container #success-container svg {
  margin-right: 16px;
}

#onboarding-link-sent-container .button {
  align-self: flex-end;
  width: 360px;
}

#onboarding-link-sent-container .button-new-onboarding {
    margin-top: 64px;
}

#onboarding-link-sent-container .button-return-menu {
    margin-top: 24px;
    margin-bottom: 50px;
}