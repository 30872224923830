/* @import url("https://fonts.googleapis.com/css2?family=Inter&display=swap"); */

:root {
  --lightGrey2: #c2c2c2;
}

.input-container {
  /* width: 19rem; */
  height: 2.365rem;
  gap: 0.5rem;
}

.input-label {
  font-size: 0.875rem;
  font-weight: "Inter";
  margin-bottom: 0.5rem;
}

.input-field {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  border: 1px solid var(--lightGrey2);
  border-radius: 0.625rem;
  font-size: 1rem;
  box-sizing: border-box;
  background-color: white; 
}

input, textarea {
  background-color: white !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;
  background-color: white !important;
}
