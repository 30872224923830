@keyframes progress-bar {
    50% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(0);
    }
}

.container,
.fixedContainer {
    width: 100%;
    height: 100dvh;
    background-color: white;
    position: relative;
    z-index: 1999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fixedContainer {
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.logoContainer {
    width: 26rem;
    height: auto;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;

    @media (max-width: 600) {
        width: 18rem;
    }
}

.loader {
    display: block;
    width: 20rem;
    height: 2px;
    position: relative;
    margin-top: 8px;

    @media (max-width: 600) {
        width: 18rem;
    }

    &::before {
        content: '';
        position: absolute;
        background: currentColor;
        top: 0;
        left: 0;
        transform: scaleX(0);
        width: 100%;
        height: 100%;
        animation: progress-bar 2s ease-in-out infinite;
    }
}
