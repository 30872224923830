.item-validation-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    float: left;
    width: 285px;
}

.item-validation-container:first-child {
  float:initial;
}

.item-validation-container:last-child {
  margin-bottom: 0px;
}

.item-validation-label {
  color: var(--darkGrey1);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.item-validation-value {
  color: var(--darkGrey1);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}