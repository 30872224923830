#other-plans-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}

#other-plans-title b {
  font-weight: 600;
}
