#video-onboarding-inputs-container {
    width: 716px;
    box-sizing: border-box;
}

#video-onboarding-inputs-container .MuiFormControl-root {
    float: left;
    width: 318px;
    margin-right: 40px !important;
}

#video-onboarding-buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 676px;
}