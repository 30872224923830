#breadcrumb-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

#breadcrumb-title {
  color: var(--black);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-left: 8px;
}